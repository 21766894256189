import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { SplashScreen } from '@/components/SplashScreen';
import { useGlobalMe } from '@/hooks/use-global-me';
import { AppRoutes, getTeamRoute } from '@/routes';

import { useTeamsQuery } from './navbar/use-team';

const useRedirectionGuard = () => {
  const { replace } = useRouter();
  const { data: teams, isInitialLoading: isTeamLoading } = useTeamsQuery({
    staleTime: 0,
  });

  const { me } = useGlobalMe();

  useEffect(() => {
    if (isTeamLoading || !teams) return;

    if (!me?.phone_number) {
      replace(AppRoutes.CREATE_PROFILE);
      return;
    }

    // NOTE: redirect when user has no teams
    if (!teams.length) {
      replace(AppRoutes.CREATE_TEAM);
      return;
    }

    const teamId = teams[0].id;

    replace(getTeamRoute(teamId));
  }, [isTeamLoading, teams, replace, me]);

  return null;
};

type Props = {};

export const RedirectionGuard = (props: Props) => {
  useRedirectionGuard();
  return <SplashScreen />;
};
